<template>
    <iframe v-if="file" :src="file.path" width="100%" height="100%">
        Ваш браузер не поддерживает плавающие фреймы!
    </iframe>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            required: true
        }
    }
}
</script>